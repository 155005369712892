// ==================================================
//  モバイル判定イベント
// ==================================================
function is_mobile() {
  const intFrameWidth = window.innerWidth;
  return intFrameWidth <= 767 ? true : false;
}

// ==================================================
//  ヘッダーメニューボタン（モバイル）
// ==================================================
const menu = document.querySelector(".header_menu-js");
const menuActiveClass = "header_menu-js-active";
const menuButton = document.querySelector(".header_menuButton-js");
const menuButtonActiveClass = "header_menuButton-js-active";
function headerMenuToggle() {
  menu.classList.toggle(menuActiveClass);
  menuButton.classList.toggle(menuButtonActiveClass);
}
menuButton.addEventListener("click", (event) => {
  headerMenuToggle();
});

// ==================================================
//  アンカーリンクのスムーススクロール
// ==================================================
const anchors = document.querySelectorAll('a[href^="#"]');
// const headerHeight = document.querySelector("header").offsetHeight;
const headerHeight = 0;
const urlHash = location.hash;

for (let i = 0; i < anchors.length; i++) {
  anchors[i].addEventListener("click", (e) => {
    e.preventDefault();

    /* ==================================================
     * ヘッダーメニューが開いていたら閉じる
     */
    if (menu.classList.contains(menuActiveClass)) {
      headerMenuToggle();
    }
    /* ================================================== */

    const href = anchors[i].getAttribute("href");
    if (href !== "#top" && href !== "#") {
      const target = document.getElementById(href.replace("#", ""));
      const position = window.pageYOffset + target.getBoundingClientRect().top - headerHeight;
      window.scroll({
        top: position,
        behavior: "smooth",
      });
    } else {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  });
}

// ==================================================
//  アンカーリンクのページ遷移位置調整
// ==================================================
window.addEventListener("load", (event) => {
  const url = location.href;
  // const headerHeight = document.querySelector("header").offsetHeight;
  const headerHeight = 0;

  if (url.indexOf("#") != -1) {
    const anchor = url.split("#");
    const target = $("#" + anchor[anchor.length - 1]);
    const position = Math.floor(target.offset().top) - headerHeight;
    window.scroll({
      top: position,
      behavior: "auto",
    });
  }
});

// ==================================================
//  スクロールと連動したアニメーション
// ==================================================
const callback = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add("scrollAnimation-js-active");
    }
  });
};
const options = {
  root: null,
  rootMargin: "70% 0px -30% 0px",
  threshold: 0,
};
let observer = new IntersectionObserver(callback, options);
const animationItems = document.querySelectorAll(".scrollAnimation-js");
animationItems.forEach((item) => {
  observer.observe(item);
});
